import React from 'react'

const LoadingSpinner = ({ size }) => {
    return <div style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    }}>
        <div className='loadingSpinner' style={{
            width: size ? size : '1rem',
            height: size ? size : '1rem',
        }} />
    </div>
}

export default LoadingSpinner
import React, { useEffect, useState } from 'react'
import { useGlobals } from '../mgt/GlobalsProvider'
import { tagBackgroundColor, tagBorderColor, tagColor } from '../mgt/constants'
import { CheckmarkIcon, InfoIcon, JiraIcon, PlusCircleIcon, TagHoleCircleIcon } from './Icons'
import { remToPixels } from '../mgt/layoutMgt'
import * as jiraAPI from '../mgt/jiraAPI'
import { convertTimestampToDate } from '../mgt/timeMgt'
import HorizontalSpacer from './HorizontalSpacer'
import { formatTimeSpent } from '../mgt/jiraAPI'
import ModularButton from './ModularButton'
import LoadingSpinner from './LoadingSpinner'

const JiraLogWorkPopupContent = ({ closePopupFunction }) => {
    const [jirasInfo, setJirasInfo] = useState({})
    const [loadingJiras, setLoadingJiras] = useState(true)
    const oneRemToPixels = remToPixels(1)
    const [addingWorkLog, setAddingWorkLog] = useState(false)
    const [comments, setComments] = useState({})
    const {
        userDoc,
        filteredLogs: logs,
    } = useGlobals()


    useEffect(() => {
        if (!logs || !userDoc) return

        getSetJiraIssues(logs)
    }, [logs, userDoc])

    function getJiraTag(log) {
        return log?.tags?.find(tag => tag.category === userDoc.settings.integrations.jira.tagCategory)
    }

    function getQuickTag(log) {
        return log?.tags?.find(tag => tag.category === '')
    }

    function getTimeSpent(issueKey, logs) {
        return logs
            .filter(log => getJiraTag(log) && getJiraTag(log).value === issueKey)
            .reduce((acc, _) => acc + 900, 0)
    }

    async function getSetJiraIssues(logs) {
        const jiraIssues = logs
            .filter(log => getJiraTag(log)) // filter out logs without Jira tags
            // New filter logic below: Remove duplicates, but include the value of the quickTag in the grouping if it exists
            // .filter((log, i, self) => {
            //     const quickTag = getQuickTag(log)
            //     const jiraTag = getJiraTag(log)
            //     return self.findIndex(t => getJiraTag(t).value === jiraTag.value && (getQuickTag(t)?.value || '') === (quickTag?.value || '')) === i
            // })
            .map(log => ({
                key: getJiraTag(log).value,
                text: log.title,
            })) // get Jira issue keys and titles
            .filter((issue, i, self) => self.findIndex(t => t.key === issue.key) === i) // remove duplicates. (issue = {key, text}, self = array of issues, i = index)
            .map(issue => ({
                key: issue.key,
                timeSpentToday: getTimeSpent(issue.key, logs),
                text: issue.text,
            })) // get time spent today for each issue

        const jiraIssues2 = [...logs]
            .sort((a, b) => a.startTime.seconds - b.startTime.seconds)
            .filter(log => getJiraTag(log)) // filter out logs without Jira tags
        const groupedLogs = {}
        jiraIssues2.forEach(log => {
            const jiraTag = getJiraTag(log)
            const quickTag = getQuickTag(log)
            const preComment = quickTag?.value || ''

            if (!groupedLogs[jiraTag.value]) {
                // JiraNo is not in groupedLogs yet:
                groupedLogs[jiraTag.value] = {
                    jiraNo: jiraTag.value,
                    entries: [{
                        startTime: log.startTime,
                        preComment: preComment,
                        timeSpent: 900,
                    }]
                }
            } else {
                // JiraNo is already in groupedLogs:
                const lastEntry = groupedLogs[jiraTag.value].entries[groupedLogs[jiraTag.value].entries.length - 1]
                const workedUntil = lastEntry.startTime.seconds + lastEntry.timeSpent
                if (log.startTime.seconds === workedUntil && preComment === lastEntry.preComment) {
                    // If the log starts right after the last entry, and the preComment is the same, add the time to the last entry:
                    lastEntry.timeSpent += 900
                } else {
                    // If the log does not start right after the last entry, or the preComment is different, add a new entry:
                    groupedLogs[jiraTag.value].entries.push({
                        startTime: log.startTime,
                        preComment: preComment,
                        timeSpent: 900,
                    })
                }
            }
        })

        console.log('Jira issues:', jiraIssues)
        console.log('Grouped logs:', groupedLogs)

        const date = convertTimestampToDate(logs[0].startTime)

        setJirasInfo(jiraIssues.reduce((acc, issue) => ({ ...acc, [issue.key]: issue }), {}))
        await handleGetWorkLog(jiraIssues, date)
        setLoadingJiras(false)
    }

    function getTagColor() {
        return userDoc.settings.tags.find(tag => tag.category === userDoc.settings.integrations.jira.tagCategory).color
    }

    async function handleGetWorkLog(jiraIssues, date) {

        for (const issue of jiraIssues) {
            const result = await jiraAPI.getJiraInfo(issue.key, userDoc, date)

            if (result) {
                console.log('Jira info for', issue.key, result)
                setJirasInfo(prev => ({ ...prev, [issue.key]: { ...prev[issue.key], ...result } }))
            } else {
                console.log('Failed to get Jira info for', issue.key)
                const newJiraInfo = { ...jirasInfo[issue.key] }
                newJiraInfo.error = true;
                setJirasInfo(prev => ({ ...prev, [issue.key]: newJiraInfo }))
            }
        }
    }

    async function logWorkToJira(issue) {
        console.log('logWorkToJira() called. addingWorkLog: ', addingWorkLog)

        if (addingWorkLog) return
        setAddingWorkLog(true)


        const issueKey = issue.key
        const date = convertTimestampToDate(getStartOfJira(issue))
        const timeSpentToday = formatTimeSpent(issue.timeSpentToday - (jirasInfo[issueKey].timeSpent))
        const comment = comments[issueKey] || ''

        if (timeSpentToday === '0m') {
            console.log('Time remaining for', issueKey, 'is 0m. Skipping log.')
        }

        console.log('Logging work to Jira:', issueKey, timeSpentToday, comment)

        const result = await jiraAPI.logWorkToJira(issueKey, comment, userDoc, date, timeSpentToday)
        if (result) {
            console.log('Logged work to Jira:', issueKey, timeSpentToday, comment)
            // const newIssueTimeSpent = { ...issueTimeSpent }
            // newIssueTimeSpent[issueKey] = issue.timeSpentToday
            // setIssueTimeSpent(newIssueTimeSpent)
            const newJiraInfo = { ...jirasInfo[issueKey] }
            newJiraInfo.timeSpent = issue.timeSpentToday
            setJirasInfo(prev => ({ ...prev, [issueKey]: newJiraInfo }))
        } else {
            console.log('Failed to log work to Jira:', issueKey, timeSpentToday, comment)
        }

        setAddingWorkLog(false)
        setComments(prev => ({ ...prev, [issueKey]: '' }))
    }

    function getStartOfJira(issue) {
        const logsCopy = [...logs]
        return logsCopy
            .sort((a, b) => a.startTime.seconds - b.startTime.seconds)
            .find(log => getJiraTag(log) && getJiraTag(log).value === issue.key).startTime
    }

    const handleCommentChange = (key, value) => {
        setComments(prev => ({ ...prev, [key]: value }))
    }

    const hasWorkToLog = Object.keys(jirasInfo).some(
        (issueKey) =>
            (jirasInfo[issueKey]?.title) &&
            (jirasInfo[issueKey].timeSpentToday - (jirasInfo[issueKey]?.timeSpent || 0)) > 0
    );

    return <>
        <div className='jiraLogWorkContainer'>
            <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%', marginBottom: '1rem' }}>
                <div className='jiraLogWorkTitle'>Log your work in Jira</div>
                <ModularButton
                    narrow={true}
                    iconStart={<PlusCircleIcon rotate={45} size={'1rem'} />}
                    onClick={closePopupFunction}
                />
            </div>
            {Object.keys(jirasInfo).map((issueKey, i) => {
                const jiraUrl = userDoc.settings.integrations.jira.issueBaseUrl + 'browse/' + issueKey

                return <div style={{ display: 'flex', width: '100%', flexDirection: 'column' }} key={i}>
                    <div className='jiraLogWorkIssueRow'>
                        <div
                            className={'growOnHover'}
                            style={{ position: 'relative', marginRight: 'auto', display: 'flex', alignItems: 'center' }}
                        >
                            <div className='hoverPopup'>
                                {!jirasInfo[issueKey]?.title ? 'Loading...'
                                    : <>
                                        <div>
                                            {jirasInfo[issueKey]?.title}
                                        </div>
                                        <div>
                                            {'Estimate: ' + formatTimeSpent(jirasInfo[issueKey]?.estimate / 60)}
                                        </div>
                                        <div>
                                            {'Total time spent: ' + formatTimeSpent(jirasInfo[issueKey]?.totalTimeSpent / 60)}
                                        </div>
                                        {jirasInfo[issueKey]?.estimate > 0 &&
                                            <div>
                                                {'Budget: ' + jirasInfo[issueKey]?.budget.toFixed(2) + '%'}
                                            </div>
                                        }
                                    </>
                                }
                            </div>
                            <InfoIcon size={oneRemToPixels * 1} />
                        </div>
                        <div style={{ minWidth: '10rem' }}>
                            <div
                                className='settingsTagCategoryName'
                                title={jiraUrl}
                                style={{
                                    backgroundColor: tagBackgroundColor(getTagColor()),
                                    border: '1px solid ' + tagBorderColor(getTagColor()),
                                    color: tagColor(getTagColor()),
                                    cursor: 'pointer',
                                }}
                                onClick={() => window.open(jiraUrl, '_blank')}
                            >
                                <TagHoleCircleIcon
                                    size={oneRemToPixels / 1.5}
                                    color={tagColor(getTagColor())}
                                />
                                {issueKey}
                            </div>
                        </div>
                        <div style={{ width: '10rem', textAlign: 'left' }}>
                            <span style={{ color: 'var(--text-color-subtle)' }}>Time spent </span>
                            {formatTimeSpent(jirasInfo[issueKey].timeSpentToday)}
                        </div>
                        <div style={{ width: '8rem', textAlign: 'left' }}>
                            <span style={{ color: 'var(--text-color-subtle)' }}>{!(jirasInfo[issueKey]?.title) ? (jirasInfo[issueKey]?.error) ? 'Not found' : 'Calculating...' : 'Logged '}</span>
                            {(jirasInfo[issueKey]?.title) && formatTimeSpent(jirasInfo[issueKey]?.timeSpent || 0)}
                        </div>
                        <div style={{ width: '9rem', textAlign: 'left' }}>
                            <span style={{ color: 'var(--text-color-subtle)' }}>{!(jirasInfo[issueKey]?.title) ? (jirasInfo[issueKey]?.error) ? '' : 'Calculating...' : 'Remaining '}</span>
                            {(jirasInfo[issueKey]?.title) && formatTimeSpent(jirasInfo[issueKey]?.timeSpentToday - ((jirasInfo[issueKey]?.timeSpent) || 0))}
                        </div>
                        <div style={{ width: '15rem' }}>
                            <input
                                type="text"
                                placeholder="Add a comment"
                                value={comments[issueKey] || ''}
                                onChange={(e) => handleCommentChange(issueKey, e.target.value)}
                                style={{
                                    all: 'unset',
                                    width: '100%',
                                    textAlign: 'left',
                                    padding: '0.25rem 0.5rem',
                                }}
                                onFocus={(e) => e.target.style.borderBottom = '1px solid var(--text-color-subtlerer)'}
                                onBlur={(e) => e.target.style.borderBottom = 'none'}
                            />
                        </div>
                        <HorizontalSpacer size={1} />
                        {(!jirasInfo[issueKey]?.title) ?
                            <div
                                style={{ marginLeft: 'auto', filter: 'grayscale(1)', cursor: 'default' }}
                            >
                                <JiraIcon size={oneRemToPixels * 1.5} style={{ paddingRight: '0.5rem' }} />
                            </div>
                            :
                            jirasInfo[issueKey].timeSpentToday - (jirasInfo[issueKey]?.timeSpent) > 0 ?
                                <div
                                    className={'growOnHover'}
                                    title={'Log' + formatTimeSpent(jirasInfo[issueKey]?.timeSpentToday - (jirasInfo[issueKey]?.timeSpent || 0)) + ' to ' + issueKey}
                                    style={{ marginLeft: 'auto', position: 'relative' }}
                                    onClick={() => logWorkToJira(jirasInfo[issueKey])}
                                >
                                    <div className='hoverPopup'>
                                        Log {formatTimeSpent(jirasInfo[issueKey]?.timeSpentToday - (jirasInfo[issueKey]?.timeSpent || 0))} to {issueKey}
                                    </div>
                                    <JiraIcon size={oneRemToPixels * 1.5} style={{ paddingRight: '0.5rem' }} />
                                </div>
                                : // nice soft green color
                                <div style={{ opacity: 0.8 }}>
                                    <CheckmarkIcon color='rgb(0, 180, 110)'
                                        size={oneRemToPixels * 1.5} style={{ paddingRight: '0.5rem' }} />
                                </div>
                        }
                    </div>
                </div>
            })}

            <ModularButton
                title={
                    loadingJiras ?
                        'Loading...' :
                        'Log all'
                }
                iconEnd={
                    loadingJiras ?
                        <LoadingSpinner size={'0.8rem'} /> :
                        hasWorkToLog ?
                            <JiraIcon /> :
                            <JiraIcon style={{ filter: 'grayscale(1)' }} />
                }
                style={{
                    marginLeft: 'auto',
                }}
                border={true}
                disabled={!hasWorkToLog || loadingJiras}
                narrow={true}
                onClick={async () => {
                    for (const issueKey of Object.keys(jirasInfo)) {
                        await logWorkToJira(jirasInfo[issueKey]);
                    }
                }}
            />
        </div>
    </>
}

export default JiraLogWorkPopupContent

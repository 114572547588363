import { handleSignOut } from "./authMgt"
import { authExpirationHours, generateAstroId, localStorageKeys, userDataExpirationHours } from "./constants"
import { auth } from "./firebase"


function clearLocalStorage() {
    Object.keys(localStorage).forEach(key => {
        localStorage.removeItem(key)
    })
}

const timeDifferenceInHours = (time) => {
    const now = new Date().getTime()
    return (now - time) / 3600000
}

function setLocalStorageUser(user) {
    try {
        if (!user) {
            localStorage.removeItem(localStorageKeys.userDoc)
            return
        }

        user.lastUpdated = new Date().getTime()
        localStorage.setItem(localStorageKeys.userDoc, JSON.stringify(user))
    } catch (error) {
        console.log('Error setting local storage user:', error)
    }
}

async function getLocalStorageUser() {
    try {
        var authResult = { result: true, text: 'OK' }
        var localStorageResult = { result: true, text: 'OK' }

        if (!auth.currentUser?.uid) {
            authResult.result = false
            authResult.text = 'User is not logged in.'
        }

        if (authResult.result === true) {
            const lastLoginAt = auth.currentUser.metadata.lastLoginAt
            if (timeDifferenceInHours(lastLoginAt) > authExpirationHours) {
                authResult.result = false
                authResult.text = 'User session expired'
            }
        }

        if (authResult.result === false) {
            console.log(`Auth [${authResult.text}] - Signing out.`)
            await handleSignOut()
            setLocalStorageUser(false)
            return false
        }

        var user = localStorage.getItem(localStorageKeys.userDoc)
        user = JSON.parse(user)
        if (!user) {
            return false
        } else {
            if (user.id !== auth.currentUser.uid) {
                localStorageResult.result = false
                localStorageResult.text = 'Local storage user does not match auth user.'
            }
        }

        if (localStorageResult.result === false) {
            console.log(`Local storage [${localStorageResult.text}] - Signing out.`)
            await handleSignOut()
            setLocalStorageUser(false)
            return false
        }

        return user
    } catch (error) {
        console.log('Error getting local storage user:', error)
        return false
    }
}

function getLocalHostId() {
    try {
        const hostId = localStorage.getItem(localStorageKeys.hostId)
        return hostId
    } catch (error) {
        console.log('Error getting local host id:', error)
        return false
    }
}

function setLocalHostId(hostId) {
    try {
        if (!hostId) {
            localStorage.removeItem(localStorageKeys.hostId)
            return
        }

        localStorage.setItem(localStorageKeys.hostId, hostId)
    } catch (error) {
        console.log('Error setting local host id:', error)
    }
}

function setLocalStorageUserComputers(computers) {
    try {
        if (!computers || computers?.length === 0) {
            localStorage.removeItem(localStorageKeys.userComputers)
            return
        }

        const timestamp = new Date().getTime()

        localStorage.setItem(localStorageKeys.userComputers, JSON.stringify(computers))
        localStorage.setItem(localStorageKeys.userComputersLastUpdated, timestamp)
    } catch (error) {
        console.log('Error setting local storage user computers:', error)
    }
}

function getLocalStorageUserComputers() {
    try {
        const lastUpdated = localStorage.getItem(localStorageKeys.userComputersLastUpdated)
        if (!lastUpdated) {
            return false
        }

        if (timeDifferenceInHours(lastUpdated) > userDataExpirationHours) {
            return false
        }

        const computers = localStorage.getItem(localStorageKeys.userComputers)
        const computersList = JSON.parse(computers)
        if (!computersList) return false

        return JSON.parse(computers)
    } catch (error) {
        console.log('Error getting local storage user computers:', error)
        return false
    }
}

function getAccessKey() {
    const key = localStorage.getItem(localStorageKeys.accessKey)
    if (!key) {
        const newKey = generateAstroId()
        setAccessKey(newKey)
        return newKey
    } else {
        return key
    }
}

function setAccessKey(key) {
    localStorage.setItem(localStorageKeys.accessKey, key)
}

function logCacheExpired() {
    const lastUpdated = localStorage.getItem(localStorageKeys.userComputerLogsLastUpdated) // returns string
    if (!lastUpdated) return true

    const lastUpdatedInt = parseInt(lastUpdated)
    if (timeDifferenceInHours(lastUpdatedInt) > userDataExpirationHours) {
        console.log('Cached logs expired.')
        localStorage.removeItem(localStorageKeys.userComputerLogs)
        localStorage.removeItem(localStorageKeys.userComputerLogsLastUpdated)
        return true
    }

    return false
}


function setLocalStorageLogs({ hostId, userId, logs }) {
    try {
        const key = localStorageKeys.userComputerLogs + hostId + userId
        console.log('Setting cached logs (' + logs?.length + ' logs)')

        if (!logs) {
            localStorage.removeItem(key)
            localStorage.removeItem(localStorageKeys.userComputerLogsLastUpdated)
            return
        }

        localStorage.setItem(key, JSON.stringify(logs))
        localStorage.setItem(localStorageKeys.userComputerLogsLastUpdated, new Date().getTime())
    } catch (error) {
        console.log('Error setting cached logs:', error)
    }
}

function getLocalStorageLogs({ hostId, userId }) {
    try {
        if (logCacheExpired()) return false

        const key = localStorageKeys.userComputerLogs + hostId + userId
        let logs = localStorage.getItem(key)
        logs = JSON.parse(logs)
        if (!logs || (logs && logs.length === 0)) return false
        return logs
    } catch (error) {
        console.log('Error getting cached logs:', error)
        return false
    }
}

function getSelectedLogsView() {
    try {
        const viewTxt = localStorage.getItem(localStorageKeys.selectedLogsView)
        const viewInt = parseInt(viewTxt)
        if (!viewInt) return 0
        return viewInt
    } catch (error) {
        console.log('Error getting selected logs view:', error)
        return 0
    }
}

function setSelectedLogsView(view) {
    try {
        const viewTxt = view.toString()
        localStorage.setItem(localStorageKeys.selectedLogsView, viewTxt)
    } catch (error) {
        console.log('Error setting selected logs view:', error)
    }
}

function getFeedbackClosed() {
    try {
        const feedbackClosed = localStorage.getItem(localStorageKeys.feedbackClosed)
        return feedbackClosed === 'true'
    } catch (error) {
        console.log('Error getting feedback closed:', error)
        return false
    }
}

function setFeedbackClosed(value) {
    try {
        const feedbackClosed = value.toString()
        localStorage.setItem(localStorageKeys.feedbackClosed, feedbackClosed)
    } catch (error) {
        console.log('Error setting feedback closed:', error)
    }
}

function getSidebarClosed() {
    try {
        const sidebarClosed = localStorage.getItem(localStorageKeys.sidebarClosed)
        return sidebarClosed === 'true'
    } catch (error) {
        console.log('Error getting sidebar closed:', error)
        return false
    }
}

function setSidebarClosed(value) {
    try {
        const sidebarClosed = value.toString()
        localStorage.setItem(localStorageKeys.sidebarClosed, sidebarClosed)
    } catch (error) {
        console.log('Error setting sidebar closed:', error)
    }
}

function getDashboardMinimized() {
    try {
        const dashboardMinimized = localStorage.getItem(localStorageKeys.dashboardMinimized)
        return dashboardMinimized === 'true'
    } catch (error) {
        console.log('Error getting dashboard minimized:', error)
        return false
    }
}

function setDashboardMinimized(value) {
    try {
        const dashboardMinimized = value.toString()
        console.log('Setting dashboard minimized:', dashboardMinimized)
        localStorage.setItem(localStorageKeys.dashboardMinimized, dashboardMinimized)
    } catch (error) {
        console.log('Error setting dashboard minimized:', error)
    }
}


export {
    clearLocalStorage,
    getLocalHostId,
    setLocalHostId,
    getAccessKey,
    getLocalStorageUser,
    setLocalStorageUser,
    setLocalStorageUserComputers,
    getLocalStorageUserComputers,
    setLocalStorageLogs,
    getLocalStorageLogs,
    getSelectedLogsView,
    setSelectedLogsView,
    getFeedbackClosed,
    setFeedbackClosed,
    getSidebarClosed,
    setSidebarClosed,
    getDashboardMinimized,
    setDashboardMinimized
}
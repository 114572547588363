// Improts
import React, { useEffect, useRef, useState } from 'react'

// Components
import LoadingText from './LoadingText'
import ModularButton from './ModularButton'
import DetailedLogsTable from './DetailedLogsTable'
import TimelineLogsTable from './TimelineLogsTable'
import SummarizedLogsTable from './SummarizedLogsTable'

// Functions
import { getSelectedLogsView, setSelectedLogsView } from '../mgt/cachingMgt'
import { ExcelIcon, JiraIcon, PlusCircleIcon } from './Icons'
import JiraLogWorkPopupContent from './JiraLogWorkPopupContent'
import { useGlobals } from '../mgt/GlobalsProvider'
import LogWorkToJiraFSPopupContent from './LogWorkToJiraFSPopupContent'

function LogsContainer({ exportLogsFunction }) {
    const [isLoading, setIsLoading] = useState(true)
    const jiraBtnRef = useRef(null)
    const {
        filteredLogs: logs,
        userDoc
    } = useGlobals()
    const [jiraLogsExist, setJiraLogsExist] = useState(false)

    useEffect(() => {
        if (!userDoc?.settings?.integrations?.jira?.tagCategory) return;
        if (!logs) return;

        const jiraLogsExist = logs
            .reduce(
                (acc, log) => {
                    !log?.tags && (log.tags = [])
                    return [...acc, ...(log.tags.map(tag => tag.category))]
                }, [] // Initial value for acc
            )
            .includes(userDoc?.settings?.integrations?.jira?.tagCategory);
        setJiraLogsExist(jiraLogsExist)
    }, [logs, userDoc]);

    const views = [
        {
            title: 'Detailed Logs',
            component: <DetailedLogsTable />
        },
        {
            title: 'Summary',
            component: <SummarizedLogsTable />
        },
        {
            title: 'Timeline',
            component: <TimelineLogsTable />
        },
    ]
    const [selectedView, setSelectedView] = useState(0)

    useEffect(() => {
        const cachedView = getSelectedLogsView()
        if (cachedView) {
            setSelectedView(cachedView)
        }
        setIsLoading(false)
    }, [])

    function switchView(i) {
        setSelectedView(i)
        setSelectedLogsView(i)
    }

    isLoading && (<LoadingText />)

    return (
        <div className='logsContainer'>
            <div className='logsContainerButtons'>
                {views.map((view, i) => {
                    const isSelected = selectedView === i
                    return (
                        <ModularButton
                            key={i}
                            title={view.title}
                            border={isSelected}
                            onClick={() => switchView(i)}
                            style={{
                                color: isSelected ? 'white' : 'var(--text-color-subtle)',
                                padding: '0.4rem 0.5rem'
                            }}
                        />
                    )
                })}
                <ModularButton
                    title='Sync to'
                    ref={jiraBtnRef}
                    border={true}
                    iconEnd={jiraLogsExist ? <JiraIcon size={'1rem'} /> : <JiraIcon size={'1rem'} style={{ filter: 'grayscale(1)' }} />}
                    style={{
                        fontSize: '1rem',
                        marginLeft: 'auto',
                        fontWeight: 500
                    }}
                    noGap={true}
                    disabled={!jiraLogsExist}
                    fullScreenPopupContent={<LogWorkToJiraFSPopupContent closePopupFunction={() => jiraBtnRef.current.closePopup()} />}
                />
                <ModularButton
                    title='Export'
                    border={true}
                    onClick={exportLogsFunction}
                    iconEnd={<ExcelIcon size={'1rem'} />}
                    style={{
                        fontSize: '1rem',
                        fontWeight: 500
                    }}
                    noGap={true}
                />
            </div>
            {views[selectedView].component}
        </div>
    )
}

export default LogsContainer
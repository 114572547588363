import React, { createContext, useContext, useState, useEffect } from 'react'
import { updateLogs } from './firestoreAPI'
import { useGlobals } from './GlobalsProvider'
const QueueContext = createContext()

/**
* @example
* const { addOperation, addNotification } = useQueue();
*/
export const useQueue = () => {
    return useContext(QueueContext)
}

export const QueueProvider = ({ children }) => {
    const [queue, setQueue] = useState([])
    const [notifications, setNotifications] = useState([])
    const [progress, setProgress] = useState(0)
    const { selectedHostId, setSelectedHostId, userDoc, setUserDoc } = useGlobals()

    function addOperation(operation) {
        console.log('Action queued:', operation)
        setQueue((prev) => [...prev, operation])
    }

    function addNotification(notification) {
        console.log('addNotification()', notification)
        setNotifications((prev) => [...prev, notification])
    }

    async function processQueue() {
        let currQueue = []
        setQueue((prevQueue) => {
            currQueue = [...prevQueue]
            return [];
        });

        // Use a small delay to ensure setQueue has completed before continuing
        await new Promise((resolve) => setTimeout(resolve, 0));

        if (currQueue.length === 0) return
        await updateLogs(userDoc.id, selectedHostId, currQueue)
    }

    useEffect(() => {
        if (!userDoc || !selectedHostId) return

        let interval = null
        let progressInterval = null

        // Handle progress increment
        progressInterval = setInterval(() => {
            setProgress((prev) => (prev < 100 ? prev + 1 : 0))
        }, 20) // Increment progress every 50ms (5s total for 100%)

        // Process queue at 5s intervals
        interval = setInterval(() => {
            setProgress(0) // Reset progress
            processQueue()
        }, 2000)

        return () => {
            clearInterval(interval)
            clearInterval(progressInterval)
        }
    }, [userDoc, selectedHostId]);

    return (
        <QueueContext.Provider value={{ addOperation, addNotification }}>
            <div
                style={{
                    filter: queue.length > 0 ? 'opacity(1)' : 'opacity(0)',
                    position: 'absolute',
                    top: '4rem',
                    left: 0,
                    zIndex: 1000,
                    height: '1px',
                    width: `${progress}%`,
                    backgroundColor: 'rgba(var(--accent-color-rgb), 0.5)',
                    transition: 'width 0.05s linear', // Smooth progress
                }}
            ></div>
            <div
                className='loadingSpinner'
                style={{
                    display: queue.length > 0 ? 'block' : 'none',
                    position: 'absolute',
                    top: '4.2rem',
                    right: '0.2rem',
                    zIndex: 1000,
                    width: '0.8rem',
                    height: '0.8rem',
                }}
            />
            {children}
        </QueueContext.Provider>
    )
}

import { getLocalHostId, setLocalHostId } from "./cachingMgt"
import { convertDateStrToTimestamp } from "./timeMgt"

export async function isAlive() {
    try {
        const result = await fetch('http://localhost:5000/is-alive')
        return result.status === 200
    } catch (error) {
        console.log('[ERROR] isAlive() - ', error)
        return false
    }
}

export async function isLoggingActive() {
    try {
        const result = await fetch('http://localhost:5000/is-logging-active')
        const status = await result.text()
        return status === 'True'
    } catch (error) {
        console.log('[ERROR] isLoggingAlive() - ', error)
        return false
    }
}

export async function getVersion() {
    try {
        const result = await fetch('http://localhost:5000/get-version')
        const version = await result.text()
        return version
    } catch (error) {
        console.log('[ERROR] getVersion() - ', error)
        return false
    }
}

export async function getHostId() {
    try {
        const localHostId = getLocalHostId()
        if (localHostId) return localHostId

        const result = await fetch('http://localhost:5000/get-host-id')
        const hostId = await result.text()

        setLocalHostId(hostId)
        return hostId
    } catch (error) {
        console.log('[ERROR] getHostId() - ', error)
        return false
    }
}

export async function getHostName() {
    try {
        const result = await fetch('http://localhost:5000/get-hostname')
        return await result.text()
    } catch (error) {
        console.log('[ERROR] getHostName() - ', error)
        return false
    }
}

export async function getHostInfo() {
    try {
        const hostId = await getHostId()
        const hostName = await getHostName()
        return { hostId, hostName }
    } catch (error) {
        console.log('[ERROR] getComputerInfo() - ', error)
        return { hostId: false, hostName: false }
    }
}

export async function pauseLogging() {
    try {
        const result = await fetch('http://localhost:5000/pause-logging')
        return result.status === 200
    } catch (error) {
        console.log('[ERROR] pauseLogging() - ', error)
        return false
    }
}

export async function startLogging() {
    try {
        const result = await fetch('http://localhost:5000/start-logging')
        return result.status === 200
    } catch (error) {
        console.log('[ERROR] startLogging() - ', error)
        return false
    }
}

export async function initialize(userId) {
    try {
        const result = await fetch('http://localhost:5000/initialize', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ userId })
        })
        return result.status === 200
    } catch (error) {
        console.log('[ERROR] initialize() - ', error)
        return false
    }
}

export async function getUnsyncedLogs() {
    try {
        const result = await fetch('http://localhost:5000/get-unsynced-logs')
        const resultJSON = await result.json()
        const parsedLogs = resultJSON.map(log => ({
            ...log,
            startTime: convertDateStrToTimestamp(log.startTime),
            logs: log.logs.map(l => ({
                ...l,
                timestamp: convertDateStrToTimestamp(l.timestamp)
            }))
        }))
        return parsedLogs
    } catch (error) {
        console.log('[ERROR] getUnsyncedLogs() - ', error)
        return false
    }
}

export async function setLogsSynced(lastTimestamp) {
    try {
        const result = await fetch('http://localhost:5000/set-logs-synced', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ lastTimestamp })
        })
        return result.status === 200
    } catch (error) {
        console.log('[ERROR] setLogsSynced() - ', error)
        return false
    }
}

export async function setAutoStartEnabled(enabled) {
    try {
        const result = await fetch('http://localhost:5000/set-auto-start', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ value: enabled })
        })
        return result.status === 200
    } catch (error) {
        console.log('[ERROR] setAutoStartEnabled() - ', error)
        return false
    }
}

export async function getJiraTimeSpent(issueKeys, userDoc, date) {
    try {
        const email = userDoc.settings.integrations.jira.email
        const token = userDoc.settings.integrations.jira.token
        const baseUrl = userDoc.settings.integrations.jira.issueBaseUrl

        const result = await fetch('http://localhost:5000/get-jira-time-spent', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                email,
                token,
                baseUrl,
                issueKeys,
                date: date.toLocaleDateString('nl-BE', {
                    year: 'numeric',
                    month: '2-digit',
                    day: '2-digit'
                })
            })
        })
        const resultJSON = await result.json()

        return resultJSON
    } catch (error) {
        console.log('[ERROR] getJiraTimeSpent() - ', error)
        return false
    }
}

export async function getJiraInfo(issueKey, userDoc, date) {
    try {
        const email = userDoc.settings.integrations.jira.email
        const token = userDoc.settings.integrations.jira.token
        const baseUrl = userDoc.settings.integrations.jira.issueBaseUrl

        const result = await fetch('http://localhost:5000/get-jira-info', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                email,
                token,
                baseUrl,
                issueKey,
                date: date.toLocaleDateString('nl-BE', {
                    year: 'numeric',
                    month: '2-digit',
                    day: '2-digit'
                })
            })
        })
        if (!result.ok) {
            console.log('Result not OK: ' + result);
            throw new Error(result.statusText)
        }
        const resultJSON = await result.json()

        return resultJSON
    } catch (error) {
        console.log('[ERROR] getJiraInfo() - ', error)
        return false
    }
}

export async function logWorkToJira(issueKey, comment, userDoc, date, timeSpent) {
    try {
        if (timeSpent === '0m') return true

        const email = userDoc.settings.integrations.jira.email
        const token = userDoc.settings.integrations.jira.token
        const baseUrl = userDoc.settings.integrations.jira.issueBaseUrl

        const result = await fetch('http://localhost:5000/log-work-to-jira', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                email,
                token,
                baseUrl,
                issueKey,
                date: date,
                timeSpent,
                comment
            })
        })
        return result.status === 200
    } catch (error) {
        console.log('[ERROR] logWorkToJira() - ', error)
        return false
    }
}
import React, { useEffect, useState } from 'react'
import FeedbackForm from './FeedbackForm'
import VerticalSpacer from './VerticalSpacer'
import ModularButton from './ModularButton'
import { RightArrowIcon } from './Icons'
import { getSidebarClosed, setSidebarClosed } from '../mgt/cachingMgt'

const Sidebar = ({ content, userComputers, computer, user, setSettingsPage }) => {
    const [hoveringCloseBtn, setHoveringCloseBtn] = useState(false)
    const [minimized, setMinimized] = useState(true)

    useEffect(() => {
        if (content === 'settings') {
            setMinimized(false)
        } else if (!getSidebarClosed()) {
            setMinimized(false)
        }
    }, [content])

    const Content = () => {
        if (minimized) {
            return <></>
        }

        switch (content) {
            case 'settings':
                return <>
                    <div className='sidebarTitle'>Settings</div>
                    <ModularButton
                        title='General'
                        onClick={() => setSettingsPage('general')}
                        border={true}
                        style={{ width: 'calc(100% - 4rem)', height: '2rem' }}
                    />
                    <VerticalSpacer size={1} />
                    <ModularButton
                        title='Tags'
                        onClick={() => setSettingsPage('tags')}
                        border={true}
                        style={{ width: 'calc(100% - 4rem)', height: '2rem' }}
                    />
                    <VerticalSpacer size={1} />
                    <ModularButton
                        title='Schedule'
                        onClick={() => setSettingsPage('schedule')}
                        border={true}
                        style={{ width: 'calc(100% - 4rem)', height: '2rem' }}
                    />
                    <VerticalSpacer size={1} />
                    <ModularButton
                        title='Integrations'
                        onClick={() => setSettingsPage('integrations')}
                        border={true}
                        style={{ width: 'calc(100% - 4rem)', height: '2rem' }}
                    />
                </>
            case 'computers':
                return <>
                    <div className='sidebarTitle'>Your computers</div>
                    {userComputers.map((userComputer, index) => {
                        const isCurrent = userComputer.hostId === computer.hostId

                        return (
                            <div
                                key={index} className='sidebarComputer'
                                style={{
                                    borderColor: isCurrent ? 'var(--text-color-subtle)' : 'var(--text-color-subtler)',
                                    cursor: isCurrent ? 'default' : 'pointer'
                                }}
                            >
                                {/* TODO: Edit computer name functionality */}
                                {/* <div className='sidebarComputerEditIcon'>
                                    <EditIcon />
                                </div> */}
                                <div className='sidebarComputerName' style={{ color: isCurrent && 'white' }}>{computer.hostName}</div>
                                <div className='sidebarComputerId'>{computer.hostId}</div>
                            </div>
                        )
                    })}
                </>
            default:
                return null
        }
    }

    return (
        <div
            className='Sidebar'
            style={{
                width: minimized ? '5rem' : '21rem',
                minWidth: minimized ? '5rem' : '21rem',
            }}
        >
            <Content />
            <FeedbackForm user={user} minimized={minimized} setMinimized={setMinimized} />
            {(content === 'computers') &&
                <div
                    className='sidebarCloseBtn'
                    onMouseEnter={() => setHoveringCloseBtn(true)}
                    onMouseLeave={() => setHoveringCloseBtn(false)}
                    onClick={() => {
                        setSidebarClosed(!minimized)
                        setMinimized(!minimized)
                    }}
                >
                    <RightArrowIcon
                        rotation={minimized ? 0 : 180}
                        size={'1rem'}
                        color={
                            hoveringCloseBtn ?
                                'var(--text-color-subtle)'
                                : 'var(--text-color-subtler)'
                        }
                    />
                </div>
            }
        </div>
    )
}

export default Sidebar
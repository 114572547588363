import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import ModularButton from '../components/ModularButton';
import { useAuthState } from 'react-firebase-hooks/auth';
import { auth } from '../mgt/firebase';
import HeaderBar from '../components/HeaderBar';
import LoadingText from '../components/LoadingText';
import VerticalSpacer from '../components/VerticalSpacer';

function NoConnection() {
    const navigate = useNavigate();
    const [user, loading] = useAuthState(auth)

    function goToDashboard() {
        navigate('/dashboard');
    }

    if (loading) return <>
        <HeaderBar />
        <LoadingText />
    </>

    return <>
        <HeaderBar />

        <div className='homeSection'>
            <div className='homeSubtitle'>🔄 TimeLogify isn't running...</div>
            <div className='homeSpacer' />
            <div className='homeSubtext'>Start the program by clicking the button below, or by searching for 'TimeLogify' in the Windows search bar.</div>
            <VerticalSpacer size={0.5} />
            <div className='homeSubtext'>If you don't have TimeLogify installed, you can <a onClick={() => navigate('/setup')} href='#'>download it here</a>.</div>
        </div>

        <div className='verticalSpacerLarge' />
        <VerticalSpacer />

        <ModularButton
            title='Open TimeLogify'
            onClick={() => window.open('timelogify://')}
            border={true}
        />

        <VerticalSpacer />
        <ModularButton
            title='Go back'
            onClick={goToDashboard}
            border={false}
            wide={true}
        />
    </>
}

export default NoConnection
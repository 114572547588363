// Imports
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useAuthState } from 'react-firebase-hooks/auth'
import { auth } from '../mgt/firebase'

// Components
import Sidebar from '../components/Sidebar'
import HeaderBar from '../components/HeaderBar'
import LoadingText from '../components/LoadingText'
import WeekSelector from '../components/WeekSelector'
import LogsContainer from '../components/LogsContainer'
import VerticalSpacer from '../components/VerticalSpacer'
import CircularProgress from '../components/CircularProgress'

// Functions
import * as timeLogifyAPI from '../mgt/timeLogifyAPI'
import * as firestoreAPI from '../mgt/firestoreAPI'
import { convertTimestampToDate } from '../mgt/timeMgt'
import { APP_VERSION, customInactiveTxt, hexToRgbForHTML, inactiveTxt } from '../mgt/constants'
import { exportToExcel, exportForVCO } from '../mgt/generalMgt'
import { useGlobals } from '../mgt/GlobalsProvider'
import { ExpandArrowsIcon, ShrinkArrowsIcon } from '../components/Icons'
import ModularButton from '../components/ModularButton'
import { getDashboardMinimized, setDashboardMinimized } from '../mgt/cachingMgt'

// Dashboard
function Dashboard() {
    const navigate = useNavigate()
    const {
        logs, setLogs,
        filteredLogs, setFilteredLogs, updateSorting,
        userDoc, user, loading,
        selectedHostId, setSelectedHostId,
        selectedDate, setSelectedDate,
        setSelectedLines
    } = useGlobals()
    const [computer, setComputer] = useState('')
    const [userComputers, setUserComputers] = useState([])
    const [logsRetrieved, setLogsRetrieved] = useState(false)
    const [refreshingLogs, setRefreshingLogs] = useState(false)
    const [isLoggingActive, setIsLoggingActive] = useState(false)
    const [expanded, setExpanded] = useState(true)

    useEffect(() => {
        if (getDashboardMinimized()) {
            setExpanded(false)
        }
    }, [])

    // Functions
    async function toggleTimeLogifyStatus() {
        if (isLoggingActive) {
            await timeLogifyAPI.pauseLogging()
            setIsLoggingActive(false)
        } else {
            await timeLogifyAPI.startLogging()
            setIsLoggingActive(true)
        }
    }

    function handleDateChange(days) {
        setSelectedLines([])
        const newDate = typeof days === 'string' ? selectSpecificDate(days) : changeDateBy(days)
        setSelectedDate(newDate)
        refreshLogs({ newDate })
    }

    function selectSpecificDate(days) {
        const dateParts = days.split('/')
        const newDate = new Date(dateParts[2], dateParts[1] - 1, dateParts[0])
        return newDate
    }

    function changeDateBy(days) {
        const newDate = days === 0 ? new Date() : new Date(selectedDate)
        newDate.setDate(newDate.getDate() + days)
        return newDate
    }

    async function refreshLogs({ newDate, initialLogs }) {
        const lLogs = initialLogs || logs
        const filteredLogs = lLogs.filter(log => {
            const logDateTime = convertTimestampToDate(log.startTime)
            const logDate00 = new Date(logDateTime.setHours(0, 0, 0, 0))
            const selectedDate00 = new Date(newDate.setHours(0, 0, 0, 0))
            const logDateStr = logDate00.toLocaleDateString('nl-BE')
            const selectedDateStr = selectedDate00.toLocaleDateString('nl-BE')

            return logDateStr === selectedDateStr
        })
        setFilteredLogs(filteredLogs)
        setTimeout(() => updateSorting(), 0)
    }

    // Initial Functions
    useEffect(() => {
        if (loading) return
        if (!user) { navigate('/login'); return }

        if (userDoc) {
            initialize()
        }
    }, [loading, user, userDoc])

    useEffect(() => {
        if (!user || !selectedHostId) return

        getLogs()
    }, [selectedHostId, user])


    async function initialize() {
        console.log('Loading DashboardV2...')

        console.log('User settings:', userDoc.settings)

        const userComputers = await getUserComputers()
        const isInstalled = await checkInstallation()

        if (!isInstalled) {
            return
        }

        await checkTimeLogifyStatus()

        userComputers &&
            await selectComputer(userComputers)

        document.documentElement.style.setProperty('--accent-color', userDoc.settings.general.accentColor)
        document.documentElement.style.setProperty('--accent-color-rgb', hexToRgbForHTML(userDoc.settings.general.accentColor))
    }

    async function getUserComputers() {
        const userComputers = await firestoreAPI.getUserComputerDocuments(user.uid)
        console.log('computers:', userComputers.map(c => c.hostId))

        if (userComputers.length === 0) {
            navigate('/setup')
            return
        }
        setUserComputers(userComputers)
        return userComputers
    }

    async function checkInstallation() {
        const timeLogifyVersion = await timeLogifyAPI.getVersion()
        console.log('Application version:', timeLogifyVersion)
        console.log('Web version:', APP_VERSION)

        if (!timeLogifyVersion) {
            console.log('Application not installed or running.')
            navigate('/no-connection')
            return false
        } else {
            if (timeLogifyVersion !== APP_VERSION) {
                navigate('/update?v=' + APP_VERSION)
                return false
            }
            return true
        }
    }

    async function checkTimeLogifyStatus() {
        const isLoggingActive = await timeLogifyAPI.isLoggingActive()
        console.log('Logging active:', isLoggingActive)

        setIsLoggingActive(isLoggingActive)
        if (isLoggingActive) {
            toggleTimeLogifyStatus()
        }
    }

    async function selectComputer(userComputers) {
        // Set this computer as selected
        const hostId = await timeLogifyAPI.getHostId()
        console.log('Host ID:', hostId)
        setSelectedHostId(hostId)
        const existingComputer = userComputers.find(computer => computer.hostId === hostId)
        if (existingComputer) {
            setComputer(existingComputer)  // TODO: fix logic when uninstalled but has logs
            return
        }
    }

    async function getLogs() {
        if (refreshingLogs || !selectedHostId) return

        setRefreshingLogs(true)
        const logs = await firestoreAPI.getUserComputerLogs({
            userId: user.uid,
            hostId: selectedHostId,
        })

        const cleanLogs = cleanupLogs(logs)

        setLogs(cleanLogs)
        refreshLogs({ newDate: selectedDate, initialLogs: cleanLogs })
        setLogsRetrieved(true)
        setRefreshingLogs(false)
        setSelectedLines([])
    }

    function handleExportToExcel() {
        exportToExcel(filteredLogs, selectedDate, userDoc?.settings?.tags || [])
    }

    function handleExportForVCO(vcoUserName) {
        exportForVCO(filteredLogs, selectedDate, userDoc?.settings?.tags || [], vcoUserName)
    }

    function cleanupLogs(logs) {
        return logs
            .map(log => {
                log.logs = log.logs.map(l => {
                    if (l.title === inactiveTxt)
                        l.title = customInactiveTxt
                    return l
                })
                if (log.title === inactiveTxt)
                    log.title = customInactiveTxt
                return log
            })
    }

    if (loading || !computer || !user || !logsRetrieved)
        return <>
            <HeaderBar />
            <LoadingText />
        </>

    return <>
        <HeaderBar noMargin={true} />
        <div className='dashboardSection' style={{ width: '100%' }}>
            <Sidebar content={'computers'} userComputers={userComputers} computer={computer} user={user} />
            <div className='dashboardMain' style={{ width: expanded ? '100%' : '60%' }}>
                <VerticalSpacer />
                <div className='dashboardControls'>
                    <CircularProgress
                        size={15}
                        selectedDate={selectedDate}
                        schedule={userDoc?.settings?.schedule}
                        isLoggingActive={isLoggingActive}
                    />
                    <WeekSelector
                        refreshLogsFunction={getLogs}
                        refreshingLogs={refreshingLogs}
                        changeDate={handleDateChange}
                        selectedDate={selectedDate}
                        schedule={userDoc?.settings?.schedule}
                        toggleTimeLogifyStatus={toggleTimeLogifyStatus}
                        isLoggingActive={isLoggingActive}
                    />
                    <ModularButton
                        iconEnd={expanded ?
                            <ShrinkArrowsIcon />
                            :
                            <ExpandArrowsIcon />
                        }
                        onClick={() => {
                            setDashboardMinimized(expanded)
                            setExpanded(!expanded)
                        }}
                        narrow={true}
                        className='dashboardExpandBtn'
                    />
                </div>
                <VerticalSpacer size={2} />
                <LogsContainer exportLogsFunction={handleExportToExcel} exportLogsForVCOFunction={handleExportForVCO} />
            </div>
        </div>
    </>
}

export default Dashboard
